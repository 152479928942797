import * as React from 'react'
import { useRouter } from 'next/router'

export function useScrollToTopElement() {
  const router = useRouter()
  const contentEl = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const handleRouteChange = (
      url: string,
      { shallow }: { shallow: boolean }
    ) => {
      if (!shallow) {
        contentEl.current?.scrollTo(0, 0)
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return contentEl
}
