import * as React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useInView } from 'react-intersection-observer'
import * as Dialog from '@radix-ui/react-dialog'
import { classNames } from '@upper/ui'
import { Logo, LogoutIcon, HamburgerIcon, CrossIcon } from '@upper/icons'
import { SlackIcon, EmailIcon } from './icons'
import { useAuth } from '@upper/auth'
import { useFellowData } from './with-fellow-data'

type MobileHeaderProps = {
  menuContent: React.ReactNode
}

export default function MobileHeader({ menuContent }: MobileHeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const { ref, inView } = useInView({
    threshold: 1,
    initialInView: true,
  })
  const isHeaderSticky = !inView
  const router = useRouter()
  const { logout } = useAuth()
  const { fellowData } = useFellowData()

  React.useEffect(() => {
    const handleRouteChange = () => {
      setIsMenuOpen(false)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <div
      ref={ref}
      className={classNames(
        'sticky z-20 py-8 -top-px md:hidden',
        isHeaderSticky && 'pointer-events-none'
      )}
    >
      <div className="px-8">
        <div className="flex items-center justify-between">
          <Link href="/">
            <a>
              <Logo
                className={classNames(
                  'w-[98px] h-auto text-blue transition-opacity',
                  isHeaderSticky && 'opacity-0'
                )}
              />
            </a>
          </Link>
          <button
            type="button"
            className={classNames(
              'inline-flex items-center justify-center w-11 h-11 text-white rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light',
              isHeaderSticky && 'pointer-events-auto'
            )}
            style={{
              background:
                'linear-gradient(138.32deg, #0128AB -0.62%, #00EBD3 235.4%)',
            }}
            onClick={() => {
              setIsMenuOpen(true)
            }}
          >
            <HamburgerIcon />
          </button>
        </div>
      </div>

      <Dialog.Root
        open={isMenuOpen}
        onOpenChange={(open) => {
          setIsMenuOpen(open)
        }}
      >
        <Dialog.Content
          className="fixed inset-0 flex flex-col text-white pb-safe state-open:animate-fade-in state-closed:animate-fade-out"
          style={{
            background:
              'linear-gradient(127.5deg, #0F44D7 -4.18%, #002AB6 97.32%)',
          }}
        >
          <div className="py-8">
            <div className="px-8">
              <div className="flex items-center justify-between">
                <Logo className="'w-[98px] h-auto" />
                <Dialog.Close className="inline-flex items-center justify-center border border-white rounded-md w-11 h-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue focus:ring-blue-light">
                  <CrossIcon className="w-5 h-5" />
                </Dialog.Close>
              </div>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto">
            <div className="max-w-md px-8 mx-auto">{menuContent}</div>
          </div>
          <div className="w-full">
            <div className="max-w-md px-8 mx-auto">
              <div className="py-6">
                <div className="bg-blue-dark rounded p-4">
                  <h3 className="text-xs text-white/50">
                    Your Engagement Manager:
                  </h3>
                  <div className="flex justify-between gap-4 mt-4">
                    <div>
                      <p>{fellowData?.em?.firstName}</p>
                      <div className="flex items-center gap-4 mt-1">
                        {fellowData?.em?.slackId && (
                          <a
                            href={`slack://user?team=TLRH1CBEJ&id=${fellowData?.em?.slackId}`}
                            className="text-white/50 hover:text-white"
                          >
                            <SlackIcon />
                          </a>
                        )}
                        <a
                          href={`mailto:${fellowData?.em?.email}`}
                          className="text-white/50 hover:text-white"
                        >
                          <EmailIcon />
                        </a>
                      </div>
                    </div>
                    {fellowData?.em?.profilePicUrl && (
                      <img
                        className="object-cover w-12 h-12 rounded-md"
                        src={fellowData?.em?.profilePicUrl}
                        alt=""
                      />
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex items-center py-2"
                    onClick={() => {
                      logout()
                    }}
                  >
                    <LogoutIcon className="w-4 h-4 opacity-50" />
                    <span className="ml-3">Logout</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </div>
  )
}
