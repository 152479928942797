import { useAuth } from '@upper/auth'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Logo,
  LogoutIcon,
  NavigationDashboardIcon,
  PlusIcon,
  ShortLogo,
} from '@upper/icons'
import { classNames, Tooltip } from '@upper/ui'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useLocalStorageState } from 'use-local-storage-state'
import { useScrollToTopElement } from '../lib/scroll'
import {
  EmailIcon,
  NavigationEarningsIcon,
  NavigationProjectsIcon,
  NavigationStaffingIcon,
  NavigationTalentIcon,
  SlackIcon,
} from './icons'
import MobileHeader from './mobile-header'
import { useFellowData } from './with-fellow-data'

type MainLayoutProps = {
  children: React.ReactNode
}

export default function MainLayout({ children }: MainLayoutProps) {
  const scrollToTopEl = useScrollToTopElement()

  return (
    <div className="bg-gray-lightest min-h-screen md:flex md:h-screen">
      <MobileHeader
        menuContent={
          <>
            <nav className="flex flex-col space-y-3">
              <NavigationItem
                icon={<NavigationDashboardIcon />}
                label="Dashboard"
                href="/"
              />
              <NavigationItem
                icon={<NavigationTalentIcon />}
                label="Staffing"
                href="/staffing"
              />
              <NavigationItem
                icon={<NavigationProjectsIcon />}
                label="Projects"
                href="/projects"
              />
              <NavigationItem
                icon={<NavigationEarningsIcon />}
                label="Earnings"
                href="/earnings"
              />
              <NavigationItem
                icon={<NavigationStaffingIcon />}
                label="Talent"
                href="/talent"
              />
            </nav>
            <div className="mt-12">
              <AddProjectButton />
            </div>
          </>
        }
      />
      <div className="hidden flex-shrink-0 md:flex">
        <Navigation />
      </div>

      <main ref={scrollToTopEl} className="md:flex-1 md:overflow-y-auto">
        {/* <div className="p-6">
          <Banner
            content={
              <div className="flex items-center flex-wrap">
                <p className="flex-1 pl-2">
                  ☃️ December Holiday Promotion: All Referral jobs are
                  compensated at a 25% payout (+15% from our standard rate)
                </p>
                <Button
                  size="xs"
                  onClick={() => router.push('/add-project/referral-job')}
                >
                  Click here to submit yours now!
                </Button>
              </div>
            }
          />
        </div> */}
        <div className="pb-12 md:py-5">
          <div className="mx-auto max-w-md px-8 sm:max-w-2xl md:mx-0 md:max-w-none md:px-0 md:pl-5 md:pr-10">
            {children}
          </div>
        </div>
      </main>
    </div>
  )
}

function Navigation() {
  const [isCollapsed, setIsCollapsed] = useLocalStorageState(
    'isNavigationCollapsed',
    true
  )
  const { logout } = useAuth()

  return (
    <div className="flex p-5">
      <div
        className={classNames(
          'relative z-10 flex flex-col rounded-md text-white shadow-lg transition-all duration-75 ease-in-out',
          isCollapsed ? 'w-[60px]' : 'w-[230px]'
        )}
        style={{
          background:
            'linear-gradient(127.5deg, #0F44D7 -4.18%, #002AB6 97.32%)',
        }}
      >
        <button
          type="button"
          className="bg-gray-dark/50 hover:bg-gray-dark focus:ring-blue-light absolute -right-4 top-8 inline-flex h-8 w-4 items-center justify-center rounded-l-none rounded-r-md text-white focus:outline-none focus:ring-2"
          onClick={() => {
            setIsCollapsed(!isCollapsed)
          }}
        >
          {isCollapsed ? (
            <ChevronRightIcon className="h-4 w-4" />
          ) : (
            <ChevronLeftIcon className="h-4 w-4" />
          )}
        </button>
        <div className="flex flex-1 flex-col">
          <div
            className={classNames(
              'flex flex-shrink-0 py-6',
              isCollapsed ? 'items-center justify-center' : 'px-4'
            )}
          >
            <Link href="/">
              <a className={classNames(isCollapsed ? 'p-2' : 'py-2')}>
                {isCollapsed ? (
                  <ShortLogo className="h-[30px] w-[30px]" />
                ) : (
                  <Logo className="h-auto w-[143px]" />
                )}
              </a>
            </Link>
          </div>
          <nav
            className={classNames(
              'flex flex-col space-y-3 px-2',
              isCollapsed && 'items-center'
            )}
          >
            <NavigationItem
              icon={<NavigationDashboardIcon />}
              label="Dashboard"
              href="/"
              isNavigationCollapsed={isCollapsed}
            />
            <NavigationItem
              icon={<NavigationTalentIcon />}
              label="Staffing"
              href="/staffing"
              isNavigationCollapsed={isCollapsed}
            />
            <NavigationItem
              icon={<NavigationProjectsIcon />}
              label="Projects"
              href="/projects"
              isNavigationCollapsed={isCollapsed}
            />
            <NavigationItem
              icon={<NavigationEarningsIcon />}
              label="Earnings"
              href="/earnings"
              isNavigationCollapsed={isCollapsed}
            />
            <NavigationItem
              icon={<NavigationStaffingIcon />}
              label="Talent"
              href="/talent"
              isNavigationCollapsed={isCollapsed}
            />
          </nav>
          <div
            className={classNames(isCollapsed ? 'mt-16 px-1' : 'mt-12 px-2')}
          >
            <div
              className={classNames(
                'flex justify-center',
                isCollapsed ? 'border-b border-t border-white/30 py-2.5' : ''
              )}
            >
              <AddProjectButton isNavigationCollapsed={isCollapsed} />
            </div>
          </div>
        </div>
        <div className="flex flex-shrink-0 flex-col py-3">
          <div className={classNames(isCollapsed ? 'px-1' : 'px-2')}>
            <EngagementManager isNavigationCollapsed={isCollapsed} />
            <nav
              className={classNames(
                'flex items-center',
                isCollapsed
                  ? 'flex-col space-y-2 border-t border-white/50 pt-1'
                  : 'justify-around py-2'
              )}
            >
              <Tooltip
                content="Logout"
                delayDuration={0}
                contentProps={{
                  side: isCollapsed ? 'right' : 'top',
                  sideOffset: isCollapsed ? 12 : 0,
                }}
              >
                <button
                  type="button"
                  className="flex items-center p-3 text-white/50 hover:text-white"
                  style={{ WebkitAppearance: 'none' }}
                  onClick={() => {
                    logout()
                  }}
                >
                  <LogoutIcon className="h-4 w-4" />
                  <span className="sr-only">Logout</span>
                </button>
              </Tooltip>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

function NavigationItem({
  icon,
  label,
  href,
  isNavigationCollapsed = false,
}: {
  icon: React.ReactElement
  label: string
  href: string
  isNavigationCollapsed?: boolean
}) {
  const router = useRouter()
  const isActive =
    href === '/' ? router.asPath === href : router.asPath.startsWith(href)

  if (isNavigationCollapsed) {
    return (
      <Tooltip
        content={label}
        contentProps={{ side: 'right', sideOffset: 12 }}
        delayDuration={0}
      >
        <span style={{ WebkitAppearance: 'none' }}>
          <Link href={href}>
            <a
              className={classNames(
                'group relative flex h-10 w-10 items-center justify-center hover:text-white focus:text-white',
                isActive ? 'text-white' : 'text-white/50'
              )}
            >
              {React.cloneElement(icon, {
                className: classNames('w-10 h-10'),
              })}
            </a>
          </Link>
        </span>
      </Tooltip>
    )
  }

  return (
    <Link href={href}>
      <a
        className={classNames(
          'group flex items-center hover:text-white focus:text-white',
          isActive ? 'text-white' : 'text-white/50'
        )}
      >
        {React.cloneElement(icon, {
          className: classNames('w-10 h-10 flex-shrink-0 mr-2'),
        })}
        <span className="flex-1 whitespace-nowrap">{label}</span>
      </a>
    </Link>
  )
}

function AddProjectButton({
  isNavigationCollapsed = false,
}: {
  isNavigationCollapsed?: boolean
}) {
  if (isNavigationCollapsed) {
    return (
      <Tooltip
        content="Add project"
        contentProps={{ side: 'right', sideOffset: 12 }}
        delayDuration={0}
      >
        <span style={{ WebkitAppearance: 'none' }}>
          <Link href="/add-project">
            <a className="bg-cyan text-blue-dark flex h-10 w-10 items-center justify-center rounded-md text-base font-medium">
              <PlusIcon />
            </a>
          </Link>
        </span>
      </Tooltip>
    )
  }

  return (
    <Link href="/add-project">
      <a className="bg-cyan text-blue-dark flex w-full items-center rounded-md px-4 py-3 text-base font-medium">
        <PlusIcon className="mr-2" />
        Add Project
      </a>
    </Link>
  )
}

function EngagementManager({
  isNavigationCollapsed = false,
}: {
  isNavigationCollapsed?: boolean
}) {
  const { fellowData } = useFellowData()

  if (isNavigationCollapsed && fellowData?.em?.profilePicUrl) {
    return (
      <div className="group relative flex justify-center py-2.5">
        <div>
          <img
            className="h-10 w-10 rounded-md object-cover"
            src={fellowData?.em?.profilePicUrl}
            alt=""
          />
        </div>
        <div className="absolute left-0.5 top-1.5 hidden w-[248px] rounded bg-[#1539A3] p-1 group-hover:block">
          <div className="flex items-center">
            <img
              className="h-10 w-10 rounded-md object-cover"
              src={fellowData?.em?.profilePicUrl}
              alt=""
            />
            <p className="ml-2.5">{fellowData?.em?.firstName}</p>
            <div className="ml-auto mr-4 flex items-center gap-4">
              <a
                href={`mailto:${fellowData?.em?.email}`}
                className="text-white/50 hover:text-white"
              >
                <EmailIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-blue-dark rounded p-4">
      <h3 className="text-xs text-white/50">Your Engagement Manager:</h3>
      <div className="mt-4 flex justify-between gap-4">
        <div>
          <p>{fellowData?.em?.firstName}</p>
          <div className="mt-1 flex items-center gap-4">
            {fellowData?.em?.slackId && (
              <a
                href={`slack://user?team=TLRH1CBEJ&id=${fellowData?.em?.slackId}`}
                className="text-white/50 hover:text-white"
              >
                <SlackIcon />
              </a>
            )}
            <a
              href={`mailto:${fellowData?.em?.email}`}
              className="text-white/50 hover:text-white"
            >
              <EmailIcon />
            </a>
          </div>
        </div>
        {fellowData?.em?.profilePicUrl && (
          <img
            className="h-12 w-12 rounded-md object-cover"
            src={fellowData?.em?.profilePicUrl}
            alt=""
          />
        )}
      </div>
    </div>
  )
}
